// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-grant-js": () => import("./../src/pages/grant.js" /* webpackChunkName: "component---src-pages-grant-js" */),
  "component---src-pages-impact-js": () => import("./../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-featured-story-page-js": () => import("./../src/templates/FeaturedStoryPage.js" /* webpackChunkName: "component---src-templates-featured-story-page-js" */)
}

